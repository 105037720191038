// Generated by Framer (ae47b7e)

import { addFonts, ComponentViewportProvider, cx, CycleVariantState, getFonts, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Hero } from "https://framerusercontent.com/modules/hKjtTuWGYB451ckw6eTN/bbq95ZQZ142lE2fXscHd/Hero.js";
const HeroFonts = getFonts(Hero);

const serializationHash = "framer-KwFiM"

const variantClassNames = {Ul4rP7YUZ: "framer-v-zroyt2"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "Ul4rP7YUZ", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-zroyt2", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"Ul4rP7YUZ"} ref={ref ?? ref1} style={{backgroundColor: "rgba(255, 255, 255, 0.1)", borderBottomLeftRadius: 100, borderBottomRightRadius: 100, borderTopLeftRadius: 100, borderTopRightRadius: 100, ...style}}><ComponentViewportProvider ><motion.div className={"framer-ej8an2-container"} layoutDependency={layoutDependency} layoutId={"gQrNuYhv6-container"}><Hero color={"rgb(255, 255, 255)"} height={"100%"} iconSearch={"Home"} iconSelection={"Play"} id={"gQrNuYhv6"} layoutId={"gQrNuYhv6"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-KwFiM.framer-1x6s2mz, .framer-KwFiM .framer-1x6s2mz { display: block; }", ".framer-KwFiM.framer-zroyt2 { height: 200px; position: relative; width: 200px; }", ".framer-KwFiM .framer-ej8an2-container { flex: none; height: 100px; left: calc(52.00000000000002% - 100px / 2); position: absolute; top: calc(50.00000000000002% - 100px / 2); width: 100px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 200
 * @framerIntrinsicWidth 200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerO5aHxblv7: React.ComponentType<Props> = withCSS(Component, css, "framer-KwFiM") as typeof Component;
export default FramerO5aHxblv7;

FramerO5aHxblv7.displayName = "Play";

FramerO5aHxblv7.defaultProps = {height: 200, width: 200};

addFonts(FramerO5aHxblv7, [{explicitInter: true, fonts: []}, ...HeroFonts], {supportsExplicitInterCodegen: true})